import FileList from 'components/FileList';
import React, { Component } from 'react';

export class Home extends Component {
  render() {
    return (
      <>
        <div className="grid-filter-panel">
          <div
            className="back-to-dashboard"
            onClick={() => {
              window.location.href = '/suite/dashboard';
            }}
          />
          <div className="bread-crum-seperator"></div>
          <div className="title">Sysco+ Uploads</div>
        </div>
        <FileList/>
      </>
    );
  }
}

export default Home;

import * as _ from 'lodash';
import * as moment from 'moment';
import { bindActionCreators } from 'redux';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Modal } from 'antd';
import { FilePdfOutlined, FileImageOutlined, FileWordOutlined, FileExcelOutlined, FileTextOutlined, LoadingOutlined, DeleteFilled, PlayCircleOutlined } from "@ant-design/icons";

import * as actionCreators from '../actions';
import Upload from './Upload';

const fileTypeClass = 'icon-file-type';

const fileTypeMap = {
  "pdf": <FilePdfOutlined className={`${fileTypeClass} red`} />,
  "image": <FileImageOutlined className={`${fileTypeClass} green`} />,
  "postscript": <FileImageOutlined className={`${fileTypeClass} green`} />,
  "msword": <FileWordOutlined className={`${fileTypeClass} blue`} />,
  "wordprocess": <FileWordOutlined className={`${fileTypeClass} blue`} />,
  "excel": <FileExcelOutlined className={`${fileTypeClass} green`} />,
  "spreadsheet": <FileExcelOutlined className={`${fileTypeClass} green`} />,
  "video": <PlayCircleOutlined className={`${fileTypeClass} purple`} />,
  "default": <FileTextOutlined className={`${fileTypeClass}`} />,
};

const getFileIcon = (mime) => {
  const selectedKeyword = Object.keys(fileTypeMap).find(keyword => `${mime}`.toLowerCase().includes(keyword));
  return fileTypeMap[selectedKeyword || 'default'];
};

export default function FileList(props) {
  const dispatch = useDispatch();
  const { getDownloadPresign, deleteFile  } = bindActionCreators(actionCreators, dispatch);

  const handleDeletion = (name, id) => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      content: `File: ${name}`,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteFile(id)
      }
    });
  };

  // Redux State
  let files = useSelector(state => _.get(state, 'files', []));
  let suvc = useSelector(state => _.get(state, 'userData.suvc', 0));
  let downloadingId = useSelector(state => _.get(state, 'loadingFlags.downloading', false));
  let deletingId = useSelector(state => _.get(state, 'loadingFlags.deleting', false));

  let suppliers = _.uniq((files || []).map(({supplier}) => supplier)).map(uSup => ({ text: uSup, value: uSup }));
  let allTypes = _.uniq((files || []).map(({type}) => type)).map(uTyp => ({ text: uTyp, value: uTyp }));

  let suvcColumn = (suvc > 0) ? {} : {
    title: 'Supplier',
    dataIndex: 'supplier',
    key: 'supplier',
    filters: [...suppliers],
    onFilter: (value, record) => record.supplier === value,
  };

  let columns = [
    {
        title: 'File Name',
        render: ({mime, name}) => {
          let mimeStr = `${mime}`;
          return <span>{getFileIcon(mimeStr)} {name}</span>;
        },
        key: 'name'
    },
    {
        title: 'Size',
        render: ({size}) => {
          size = parseInt(`${size}`);
          let displaySize = size;
          let displayUnit = 'b';
          if (size>1024) {
            displaySize =  size/1024;
            displayUnit = 'K'
          }
          if (size>(1024*1024)) {
            displaySize =  size/(1024*1024);
            displayUnit = 'M'
          }
          if (size>(1024*1024*1024)) {
            displaySize =  size/(1024*1024*1024);
            displayUnit = 'G'
          }
          return <>{(Math.round(displaySize*10)/10)} {displayUnit}</>;
        },
        key: 'size',
        align: 'right'
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        filters: [...allTypes],
        onFilter: (value, record) => record.type === value
    },
    suvcColumn,
    {
        title: 'Uploaded By',
        dataIndex: 'uploaded_by',
        key: 'uploaded_by'
    },
    {
        title: 'Uploaded At',
        render: ({uploaded_at}) => {
          const offsetMinutes = new Date().getTimezoneOffset(); 
          const localTime = moment.utc(uploaded_at).utcOffset(-offsetMinutes);
          return <>{localTime.format("MM/DD/YYYY HH:mm:ss")}</>;
        },
        key: 'uploaded_at'
    },
    {
        title: 'Actions',
        render: ({is_deleted, status, name, id}) => {
            if (is_deleted == 1) {
                return <span className="red-message">Deleted by User</span>
            } else {
                if (status == 0) {
                    return <span className="red-message">Upload Error</span>
                } else {
                  let downloading = downloadingId == id;
                  let deleting = deletingId == id;
                    return <>
                        <Button type='primary' onClick={() => { getDownloadPresign(id) }} disabled={!!downloading}>{downloading ? <><LoadingOutlined /> Downloading</> : 'DOWNLOAD'}</Button>
                        { suvc > 0 && (deleting ? <LoadingOutlined style={{marginLeft: '10px'}} /> : <DeleteFilled className="file-delete" onClick={() => { handleDeletion(name, id) }} /> )}
                    </>
                }
            }
        },
        key: 'is_deleted'
    }
    ];

  return (
    <React.Fragment>
      <>
        <div className="file-list-section">
        {suvc > 0 && <Upload />}
        {
        (files && files.length > 0) ? <Table
          id="files-table"
          rowKey={'id'}
          columns={columns}
          dataSource={files}
          pagination={false}
          rowClassName={(record) =>
            record.newFile ? "new-file-row" : ""
          }
        /> : <div className='no-data-message'>No Files...</div>
        }
        </div>
      </>
    </React.Fragment>
  );
}
const initialState = {
  userData: {},
  files: [],
  fileTypes: [],
  loadingFlags: {
    list: null,
    uploading: false,
    downloading: 0,
    deleting: 0
  },
  currentUpload: {},
  currentFile: {}
};
export default initialState;

import { put } from 'redux-saga/effects';
import { deleteRequest, externalFileUploadRequest, getRequest, putRequest } from '_http';
import * as at from 'actions/actionTypes';

import * as _ from 'lodash';
import { action } from 'reduxHelpers';


function* loadUserAsync() {
  try {
    const response = yield getRequest(`/list`);
    yield put({ type: at.USER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: at.USER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'FILES LIST LOADING ERROR'
      })
    );
  }
}

function* uploadPresignGetAsync({fileObj}) {
  try {
    let {name, type, size} = fileObj.file;
    let typeSelected = fileObj.selectedtype;
    let supplier = '';
    let localSuvc = '';
    try {
      supplier = JSON.parse(window.localStorage.getItem('vendor')).name || '';
      localSuvc = JSON.parse(window.localStorage.getItem('vendor')).suvc || '';
      if (localSuvc) {
        supplier = `${localSuvc} - ${supplier}`;
      }
    } catch (err) {}
    let sendingDataObj = {name, mime: type, size, type: typeSelected, supplier};
    console.log(`sendingDataObj: `, sendingDataObj);
    const response = yield getRequest(`/uploadlink/${encodeURIComponent(JSON.stringify(sendingDataObj))}`);
    yield put({ type: at.UPLOADPRESIGN.SUCCESS, payload: response.data });
    const {url, record_id} = response.data;
    console.log(`url:`, url)
    const response2 = yield externalFileUploadRequest(url, fileObj.file);
    if (response2.ok) {
      yield put({ type: at.UPLOADPRESIGN.UPLOAD_COMPLETE, payload: {} });
      const response3 = yield putRequest(`/file/${record_id}`, {});
      yield put({ type: at.UPLOADPRESIGN.UPLOAD_CONFIRMED, payload: response3.data });
    } else {
      throw { message: 'Upload Failed' }
    }

  } catch (error) {
    yield put({ type: at.UPLOADPRESIGN.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'UPLOAD ERROR'
      })
    );
  }
}

function* downloadPresignGetAsync({id}) {
  try {
    const response = yield getRequest(`/downloadlink/${id}`);
    let url = _.get(response, 'data.url', false);
    if (url) {
      yield put({ type: at.DOWNLOADPRESIGN.SUCCESS, payload: response.data });
      window.open(url, "_blank");
    } else {
      throw {message: 'Failed getting a download link'}
    }
  } catch (error) {
    yield put({ type: at.DOWNLOADPRESIGN.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'DOWNLOAD ERROR'
      })
    );
  }
}

function* deleteFileAsync({id}) {
  try {
    const response = yield deleteRequest(`/file/${id}`);
    yield put({ type: at.DELETEFILE.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: at.DELETEFILE.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'FAILED DELETION'
      })
    );
  }
}


export { loadUserAsync, uploadPresignGetAsync, downloadPresignGetAsync, deleteFileAsync };

import * as _ from 'lodash';
import * as moment from 'moment';
import { bindActionCreators } from 'redux';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Select } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";

import * as actionCreators from '../actions';

export default function Upload(props) {
  const [file, setFile] = useState(null);
  const [selectedtype, setSelectedtype] = useState("");
  const [message, setMessage] = useState("");
  const [lastFileLink, setLastFileLink] = useState("");

  const { Option } = Select;

  const dispatch = useDispatch();
  const { getUploadPresign  } = bindActionCreators(actionCreators, dispatch);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // Redux State
  let fileTypes = useSelector(state => _.get(state, 'fileTypes', []));
  let uploading = useSelector(state => _.get(state, 'loadingFlags.uploading', false));

  const handleOnSelect = async (event) => {
    setSelectedtype(`${event}`);
  };

  const handleUpload = async () => {
    if (!file){
      setMessage("Please select a file.");
      return;
    }
    setMessage("");
    getUploadPresign({file, selectedtype});
  };

  return (
    <React.Fragment>
      <>
        <div className="upload-panel-wrapper">
            <div>
              <input type="file" onChange={handleFileChange} className="custom-file-input" />
              <Select placeholder="Select type" className='type-selection-dropdown' onSelect={handleOnSelect} dropdownMatchSelectWidth={false}>
                {((fileTypes || []).map(fileTyp => (<Option value={fileTyp}>{fileTyp}</Option>)))}
              </Select>
              <Button onClick={handleUpload} disabled={!!uploading}>{uploading ? <><LoadingOutlined /> Uploading</>: 'UPLOAD'}</Button>
            </div>
            {message && <div className="upload-message">{message}</div>}
          <div style={{width: '350px', overflowX: 'hidden'}}>
            {lastFileLink && <a href={lastFileLink} target='_blank' rel="noopener noreferrer">{lastFileLink}</a>}
          </div>
        </div>
      </>
    </React.Fragment>
  );
}
import { combineReducers } from 'redux';
import initialState from 'store/initialState';
import * as ActionTypes from 'actions/actionTypes';
import * as _ from 'lodash';

const userData = (state = initialState.userData, action) => {
  switch (action.type) {
    case ActionTypes.USER.SUCCESS:
      return { ...state, ..._.get(action.payload, 'userData', {})};
    default:
      return state;
  }
};

const replaceItemInList = (list, item) => {
  let editList = _.cloneDeep(list);
  editList = editList.map(row => (row.id == item.id) ? item : row );
  return editList;
};

const files = (state = initialState.files, action) => {
  switch (action.type) {
    case ActionTypes.USER.SUCCESS:
      return [ ...state, ..._.get(action.payload, 'files', [])];
    case ActionTypes.USER.FAILURE:
      return [];
    case ActionTypes.UPLOADPRESIGN.UPLOAD_CONFIRMED:
      let file = _.get(action.payload, 'file', {});
      file.newFile = true;
      return [...[file], ...state];
    case ActionTypes.DELETEFILE.SUCCESS:
      let file2 = _.get(action.payload, 'file', {});
      return [ ...replaceItemInList(state, file2) ];
    default:
      return state;
  }
};

const fileTypes = (state = initialState.fileTypes, action) => {
  switch (action.type) {
    case ActionTypes.USER.SUCCESS:
      return [ ...state, ..._.get(action.payload, 'fileTypes', [])];
    default:
      return state;
  }
};

const loadingFlags = (state = initialState.loadingFlags, action) => {
  switch (action.type) {
    case ActionTypes.USER.REQUEST:
      return { ...state, list: true };
    case ActionTypes.USER.SUCCESS:
      return { ...state, list: false };
    case ActionTypes.USER.FAILURE:
      return { ...state, list: false };
    case ActionTypes.UPLOADPRESIGN.REQUEST:
      return { ...state, uploading: true };
    case ActionTypes.UPLOADPRESIGN.UPLOAD_CONFIRMED:
      return { ...state, uploading: false };
    case ActionTypes.UPLOADPRESIGN.FAILURE:
      return { ...state, uploading: false };
    case ActionTypes.DOWNLOADPRESIGN.REQUEST:
      return { ...state, downloading: action.id };
    case ActionTypes.DOWNLOADPRESIGN.SUCCESS:
      return { ...state, downloading: 0 };
    case ActionTypes.DOWNLOADPRESIGN.FAILURE:
      return { ...state, downloading: 0 };
    case ActionTypes.DELETEFILE.REQUEST:
      return { ...state, deleting: action.id };
    case ActionTypes.DELETEFILE.SUCCESS:
      return { ...state, deleting: 0 };
    case ActionTypes.DELETEFILE.FAILURE:
      return { ...state, deleting: 0 };      
    default:
      return state;
  }
};

const currentUpload = (state = initialState.currentUpload, action) => {
  switch (action.type) {
    case ActionTypes.UPLOADPRESIGN.REQUEST:
      return { ...state, uploadStatus: 'REQUEST_URL' };
    case ActionTypes.UPLOADPRESIGN.SUCCESS:
      return { ...state, ...action.payload, uploadStatus: 'URL_RECEIVED' };
    case ActionTypes.UPLOADPRESIGN.UPLOAD_COMPLETE:
      return { ...state, uploadStatus: 'UPLOADED' };
    case ActionTypes.UPLOADPRESIGN.UPLOAD_CONFIRMED:
      return { ...state, uploadStatus: 'CONFIRMED' };
    default:
      return state;
  }
};

const currentFile = (state = initialState.currentFile, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({
  userData,
  files,
  fileTypes,
  loadingFlags,
  currentUpload,
  currentFile
});

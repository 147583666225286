import { takeLatest } from 'redux-saga/effects';
import * as at from 'actions/actionTypes';
import { loadUserAsync, uploadPresignGetAsync, downloadPresignGetAsync, deleteFileAsync } from './UserSaga';

const userSaga = [
    takeLatest(at.USER.REQUEST, loadUserAsync),
    takeLatest(at.UPLOADPRESIGN.REQUEST, uploadPresignGetAsync),
    takeLatest(at.DOWNLOADPRESIGN.REQUEST, downloadPresignGetAsync),
    takeLatest(at.DELETEFILE.REQUEST, deleteFileAsync)
];

export default userSaga;
